import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styles from "./login.module.css";
import axiosConfig from "../axiosConf.js";
import { useHistory } from "react-router-dom";
import Logo from "../images/asm.png";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  const submit = () => {
    if (email === "") {
      setError("Oops.. email address cannot be empty.");
      return;
    }
    if (password === "") {
      setError("Oops.. password cannot be empty.");
      return;
    }

    try {
      axiosConfig
        .post("/checklogin.php", { email: email, password: password })
        .then((response) => {
          //console.log(response);
          history.push("/");
        })
        .catch((error) => {
          setError(error.response?.data);
        });
    } catch (error) {
      setError("An error occured.");
    }
  };

  return (
    <Container className={styles["main-container"]} fluid>
      <Row className={styles["login-row"]}>
        <Col className={styles["login-col"]} xs={12} md={8} lg={5} xl={3}>
          <Container>
            <Row className={styles["login-title"]}>
              <img src={Logo} />
            </Row>
            <Row className={styles["login-tag-line"]}>Viaduct App Control</Row>
            <Row className={styles["input-text"]}>Email Address</Row>
            <Row>
              <input
                class="form-control"
                type="email"
                placeholder="name@address.com"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError("");
                }}
              />
            </Row>
            <Row className={styles["input-text"]}>
              <Col>Password</Col>
              <Col className={styles["forgot-password"]}>Forgot Password?</Col>
            </Row>
            <Row>
              <input
                className="form-control"
                type="password"
                placeholder="Enter your password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError("");
                }}
              />
            </Row>
            <Row className={styles["error-row"]}>{error}</Row>
            <Row>
              <Button
                onClick={() => submit()}
                className={styles["signin-button"]}
              >
                Sign in
              </Button>
            </Row>
            <Row className={styles["sign-up-row"]}>
              <div className={styles["no-account-text"]}>
                Dont have an account yet?
              </div>
              <div className={styles["sign-up-text"]}>Sign up</div>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
export default Login;
