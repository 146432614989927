import React from "react";

function ImagePreview(props) {
  return (
    <div>
      {props.data && (
        <img
          style={{ maxHeight: "100px", maxWidth: "100px" }}
          src={props.data}
        />
      )}
    </div>
  );
}
export default ImagePreview;
