import { useState, useEffect } from "react";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

function DateTimePickerField(props) {
  const [value, setValue] = useState(new Date());

  function newValue(e) {
    console.log(e);
    props.value_changed({ value: e, index: props.index });
    setValue(e);
  }

  useEffect(() => {
    if (props.data?.current_value) {
      setValue(props.data.current_value);
    }
  }, [props.data.current_value]);

  return (
    <div>
      <DateTimePicker
        format={"y-MM-dd HH:mm:ss"}
        onChange={(e) => newValue(e)}
        value={value}
      />
    </div>
  );
}

export default DateTimePickerField;
