import React, { useState } from "react";
import { Container, Row, Dropdown, DropdownButton } from "react-bootstrap";
import Header1 from "../Components/Headers/Header1";
import Table from "../Components/Tables/TableComponent";
import styles from "./prizes.module.css";
import GenericModal from "../Components/Modals/GenericModal";
import TextInput from "../Components/Tables/CustomComponents/TextInput";
import TextArea from "../Components/Tables/CustomComponents/TextAreaInput";
import DayDropDown from "../Components/Tables/CustomComponents/DayDropDown";
import ImageInput from "../Components/Tables/CustomComponents/ImageInput";
import ImagePreview from "../Components/Tables/CustomComponents/ImagePreview";

const components = {
  DayDropDown: DayDropDown,
  Text: TextInput,
  Image: ImageInput,
  ImagePreview: ImagePreview,
  TextArea: TextArea,
};

function AboutImages() {
  const [showModal, setShowModal] = useState(false);
  const [updateData, setUpdateData] = useState(0);

  const headers = [
    {
      title: "Image",
      value: "image",
      editable: true,
      modal_component: "Image",
      custom_component: "ImagePreview",
    },
    {
      title: "Order",
      value: "priority",
      editable: true,
      place_holder: "1",
      modal_component: "Text",
    },
  ];
  const modalData = {
    id: undefined,
    row: [
      {
        title: "Image",
        value: "image",
        editable: true,
        place_holder: "",
        modal_component: "Image",
        custom_component: "Image",
      },
      {
        title: "Order",
        value: "priority",
        editable: true,
        place_holder: "1",
        modal_component: "Text",
      },
    ],
  };
  return (
    <>
      <Container className={styles["main-container"]} fluid>
        <Row>
          <Header1
            title={"About Images"}
            buttonText={"Add Image"}
            showModal={(e) => setShowModal(modalData)}
          />
        </Row>
        <Row className={styles["table-row"]}>
          <Table
            components={components}
            headers={headers}
            onUpdate={updateData}
            deleteEP={"/deleteaboutimages.php"}
            getEP={"/aboutusimages.php"}
            canSelectRow={true}
            showModal={(e) => setShowModal(e)}
          />
        </Row>
      </Container>

      {showModal != false && (
        <GenericModal
          components={components}
          modalData={modalData}
          reload={() => setUpdateData(updateData + 1)}
          addEP={"/addaboutimage.php"}
          deleteEP={"/deleteaboutimages.php"}
          editEP={"/updateaboutimage.php"}
          show={true}
          data={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
}
export default AboutImages;
