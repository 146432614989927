// First we need to import axios.js
import axios from "axios";
// Next we make an 'instance' of it
const instance = axios.create({
  // .. where we make our configurations
  baseURL: "https://www.apnuk.net/Viaduct2023/dashboard/",
});

// Where you would set stuff like your 'Authorization' header, etc ...
instance.defaults.withCredentials = true;

// Also add/ configure interceptors && all the other cool stuff
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location = "/Viaduct2023/dashboard/ux/login";
    }
    return Promise.reject(error);
    return error;
  }
);

export default instance;
