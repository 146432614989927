// First we need to import axios.js
import axios from "axios";
// Next we make an 'instance' of it
const instance = axios.create({
  // .. where we make our configurations
  baseURL: "https://notifications.apnuk.net/viaduct/sendtoall",
});

instance.defaults.headers.common = { Bearer: `DONKEYSaddle21!` };

// Also add/ configure interceptors && all the other cool stuff
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location = "/";
    }
    return Promise.reject(error);
    return error;
  }
);

export default instance;
