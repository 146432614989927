import { Container, Row, Col } from "react-bootstrap";
import styles from "./app.module.css";
import Menu from "./Components/Menu";
import TopMenu from "./Components/TopMenu";
import Logo from "./images/asm.png";
import ViewCustomers from "./Pages/ViewCustomers";
import Prizes from "./Pages/Prizes";
import ShakeLog from "./Pages/ShakeLog";

import WhatsOn from "./Pages/WhatsOn";
import Events from "./Pages/Events";
import Offers from "./Pages/Offers";
import Videos from "./Pages/Videos";
import Mixes from "./Pages/Mixes";

import { Switch, Route } from "react-router-dom";
import Notifications from "./Pages/Notifications";
import OpeningTimes from "./Pages/OpeningTimes";
import Team from "./Pages/Team";
import AboutImages from "./Pages/AboutImages";
import BrunchEvents from "./Pages/BrunchEvents";

function App() {
  return (
    <Container className={styles["main-container"]} fluid>
      <Row className={styles["content-row"]}>
        <div className="d-none d-md-block">
          {" "}
          <Col className={styles["menu-col"]}>
            <div>
              <Container>
                <Row className={styles["logo-row"]}>
                  <img src={Logo} />
                </Row>
                <Row>
                  <Menu
                    didSelect={() => null}
                    activeKey={"0"}
                    selectedTab={0}
                    activeChild={"1"}
                  />
                </Row>
              </Container>
            </div>
          </Col>
        </div>
        <div className="d-block d-md-none">
          <TopMenu />
        </div>
        <Col className={styles["content-col"]}>
          <Switch>
            <Route path="/" exact>
              <ViewCustomers />
            </Route>
            <Route path="/customers" exact>
              <ViewCustomers />
            </Route>
            <Route path="/shakelog" exact>
              <ShakeLog />
            </Route>
            <Route path="/prizes" exact>
              <Prizes />
            </Route>

            <Route path="/whatson" exact>
              <WhatsOn />
            </Route>
            <Route path="/events" exact>
              <Events />
            </Route>
            <Route path="/offers" exact>
              <Offers />
            </Route>

            <Route path="/team" exact>
              <Team />
            </Route>
            <Route path="/opening" exact>
              <OpeningTimes />
            </Route>
            <Route path="/notification" exact>
              <Notifications />
            </Route>
            <Route path="/aboutimages" exact>
              <AboutImages />
            </Route>
            <Route path="/brunches" exact>
              <BrunchEvents />
            </Route>
          </Switch>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
